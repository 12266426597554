import TauxEchec from './TauxEchec/TauxEchec';
import TauxReussite from './TauxReussite/TauxReussite';
import VenteAdditionnelles from './VenteAdditionnelles/VenteAdditionnelles';
import RepartitionProduit from './RepartitionProduit/RepartitionProduit';
import Repartitiongeographique from './RepartitionGeographique/RepartitionGeographique';
import PoidsJours from './PoidsJours/PoidsJours';
import { useEffect } from 'react';

const WrapperDisplay = ({sectionDisplay, checked, filter, filterJson, test }) => {
        let component;

        switch(sectionDisplay){
                case "tauxReussite":
			component = <TauxReussite checked={checked} test={test} />;
			break; 
                case "tauxEchec":
                        component = <TauxEchec/>;
                        break; 
                case "venteAdditionnelles":
			component = <VenteAdditionnelles/>;
			break;
                case "poidsJours":
                        component = <PoidsJours/>;
                        break;
                case "repartitiongeographique":
                        component = <Repartitiongeographique/>;
                        break;
                case "repartitionProduit":
                        component = <RepartitionProduit filter={filter} filterJson={filterJson} />;
                        break;
                default:
                        component = <TauxReussite checked={checked} test={test} />;
			break;
        }

        return (
                <>
                        {component}
                </>
          );

}
 
export default WrapperDisplay;