const RepartitionProduit = ({filter, filterJson}) => {

        console.log(filter);
        console.log(filterJson);
        return ( 
                <>
                
                <div>------------------------------------</div>
                <div>Json</div>
                <div>RepartitionProduit</div>

                <ul>
                        <li>{filterJson.entity}</li>
                        <li>{filterJson.website}</li>
                        <li>{filterJson.formular}</li>
                        <li>{filterJson.inputValueRealisedBy}</li>
                        <li>{filterJson.device}</li>
                        <li>{filterJson.period}</li>
                        <li>{filterJson.intervalCalendarBegin.toString()}</li>
                        <li>{filterJson.intervalCalendarEnd.toString()}</li>
                        <li>Allo {filterJson.intervalMonth.toString()}</li>
                        <li>{filterJson.intervalYear}</li>
                        <li>{(filterJson.checkboxCompared).toString()}</li>
                        <li>{(filterJson.lastChange).toString()}</li>

                </ul> 
                </>
         );
}
 
export default RepartitionProduit;