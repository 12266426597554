import styled from 'styled-components';

export const StyledWrapperPage = styled.div`
        padding: 10px;
        background: ${props => props.color ? props.color : "palevioletred"};
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 100vh;
 `;


export const StyledWrapperButton = styled.div`
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
        flex-wrap: wrap ;
        gap:30px;
`

export const StyledWrapperDisplay = styled.div`
        /* display: flex;
        
        align-self:stretch;
        background: white;

        margin-top:20px; */
        width: 100%;
        background: white;   
        flex-grow:1;
`
export const StyleWrapperBorder = styled.div`
        border:2px solid;
        height:100px;
        position:relative;

        > h3{
                position:absolute;
                top:-35px;
                left:45px;
                background: #eeeeee;   

                padding:0 10px;    
        }

`


export const StyledFlexDiv = styled.div`
        display: flex;
        flex-direction:row;
        
`

export const StyledFieldSet = styled.fieldset`
        margin: 4px 4px 4px 4px;
`