import React from "react";

import {BrowserRouter, Routes, Route, useParams, useLocation, useSearchParams} from "react-router-dom";
import Drawer from "./Drawer";
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact/Contact';
import Stats from './pages/Stats/Stats';
import { useEffect, useState } from "react";
import axios from 'axios';




export default function App() {

  //rajout pour json
  useEffect(() => {
    axios.get("http://asrgroupev2.com/")
    .then(response => {
      console.log("response.data : "+response.data);
      console.log("reponse : "+response);
      console.log('Response from main API: '+response);

      console.log('JSON.stringify: '+JSON.stringify(response));


      return(response.data);
})
.catch(error => console.log(error))
}, []);


    // useEffect(() => {
    //   fetch("https://www.asrgroupe.com/stats-jsonmaker.php", {
		// 		method: 'GET',

		// 		headers: {
		// 			"Content-type": "application/json",
		// 			"Authorization": "Bearer "
		// 		}
		// 	})
    //     .then((res) => {
    //       console.log("du fetch "+res);
    //       return res;
    //     })
    //     .catch(err => {
    //       //console.log("Une erreur s'est produite " + err)
    //       return err;
    //     });  
    // }, []);

  let location = useLocation();
  const user = location.search.split('=')[1];
  const user2=user;

  const tab = [];
  tab.push(user);

  const params = useParams();

    // function BlogPost() {
    //   let location = useLocation();

 
    //   let { id } = useParams();
    //   return <h1>
    //            Bonjour utilisateur : {location.search.split('=')[1]} 
    //          </h1>;
    // }
    const [myUser,setMyUser] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(()=>{
      setMyUser(location.search);
      //console.log(location);
      console.log("myUser");

      console.log({myUser});
    }, []);


    const truc = "bonjour";

    const name = new URLSearchParams(useLocation().search).get('user');

    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    
    const [testxyz] = useSearchParams();

    const storeSessionUser = localStorage.setItem(('userFromLocation'),JSON.stringify(myUser));


  return (
    <div>
      <Drawer>
      {/* <Routes> */}
                        {/* route pour une url donnée */}
                        {/* <Route path="/home" exact element={<Home />} />
                        <Route path="/utilisateurs" exact element={<About />} />
                        <Route path="/contact" exact element={<Contact/>} />                        */}
        {/* </Routes>  */}
         <h1>
               {/* Bonjour utilisateur : user {user}  user2 {user2} myUser {myUser} searchParams = {searchParams}   name = {name}  query = {query} tab = {tab} */}
               Bonjour utilisateur : myUser {myUser}
        </h1>




        <Routes>
                        {/* route pour une url donnée */}
                        <Route path="/home" exact element={<Home name={query} />} />
                        <Route path="/about" exact element={<About />} />
                        <Route path="/stats" exact element={<Stats user={searchParams}   userSearchParam= {searchParams} name = {name} tab={tab}  userMyUser={myUser} truc={truc} bidule={testxyz}    />} />  

                        <Route path="/contact" exact element={<Contact/>} />
                        {/* {user ? <Route path="/stats" exact element={<Stats user={user}/>} /> : <Route path="/stats" exact element={<Stats/>} /> } */}
                               



        </Routes> 

        </Drawer>
    </div>
  );
}



