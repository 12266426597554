import Button from '@mui/material/Button';
import { style } from '@mui/system';



const Contact = () => {
        return ( <div>Contact</div> );
}
 
export default Contact;

