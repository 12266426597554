import { useState } from "react";

import React from'react';
import Button from '@mui/material/Button';
import { StyledWrapperPage, StyledWrapperDisplay, StyledFlexDiv, StyledFieldSet } from './style';
import WrapperDisplay from "./WrapperDisplay/WrapperDisplay";
import { StyledWrapperButton,StyleWrapperBorder } from "./style";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fr } from "date-fns/locale";
import { style } from '@mui/system';
import { useEffect } from "react";
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';

const Stats = ({user, userSearchParam, name, tab, userMyUser, truc, bidule}) => {

        //rajout pour json
//         useEffect(() => {
//         axios.get("http://asrgroupev2.com/user")
//         .then(response => {
//           console.log("response.data : "+response.data);
//           console.log("reponse : "+response);
//           console.log('Response from main API: '+response);
    
//           console.log('JSON.stringify: '+JSON.stringify(response));
    
    
//           return(response.data);
//     })
//     .catch(error => console.log(error))
//     }, []);




        //rajout pour json
        useEffect(() => {
                console.log("https://www.asrgroupe.com/stats-jsonmaker.php"+userMyUser);
                axios.get("https://www.asrgroupe.com/stats-jsonmaker.php"+userMyUser)
                .then(response => {
                  console.log("response.data : "+response.data);
                  console.log("reponse : "+response);
                  console.log('Response from main API: '+response);
            
                  console.log('JSON.stringify: '+JSON.stringify(response));
            
            
                  return(response.data);
            })
            .catch(error => console.log(error))
            }, []);


    
        const [display, setDisplay] = useState('');

        const [xyz, setxyz] = useState('tesssst');


        console.log("user :");
        console.log({user});

        console.log("userSearchParam :");
        console.log({userSearchParam});

        console.log("name :");
        console.log({name});
        console.log("tab");
        console.log(tab);

        const [test] = useSearchParams();
        const mavar= "oula";
        const [mavar2,setMaVar2] = useState('allo');

        let entiteData = ['Franssur', 'SVI Assurance Lyon', 'Amophys', 'Svi Assurances Beauvais', 'Arca', 'SVI Assurance Roissy', 'TBC Assurances', 'Aero Courtage'];

        let websiteData = ['ARCA Transport', 'TBC, Assurance', 'RCPROASSUR', 'DroneAssur', 'CoproAssur', 'Ambulance', 'Assurances Pro Auto', 'Assurance FoodTruck', 'Lisboabnb', 'Aero Courtage', 'ARCA VTC', 'ARCA Poids Lourd'];

        let formularData = ['formular 1', 'formular 2', 'formular 3'];

        let userCategoryData = ['prospect', 'Utilisateur ASR', 'Tous'];

        let userAsrData = ['Jacky', 'Bertrand', 'Philippe'];

        let periodicityData = ['Aujourd\'hui', '30 derniers jours', '12 derniers mois', 'Toute la periode'];

        let deviceData = ['Mobile', 'Tablette', 'Ordinateur portable', 'Ordinateur fixe', 'Tous'];



//        const [siteData, setSiteData] = useState(
//                [
//                         { value: '1', text: 'ARCA Transport' },
//                         { value: '2', text: 'TBC, Assurance' },
//                         { value: '3', text: 'RCPROASSUR' }
//                 ]  
//        );

//        const [formulaireData, setFormulaireData] = useState(
//         [
//                  { value: '1', text: 'Formulaire ARCA Transport 1' },
//                  { value: '1', text: 'Formulaire ARCA Transport 2' },
//                  { value: '2', text: 'Formulaire TBC, Assurance 1' },
//                  { value: '3', text: 'Formulaire RCPROASSUR 1' },
//                  { value: '3', text: 'Formulaire RCPROASSUR 2' },
//                  { value: '3', text: 'Formulaire RCPROASSUR 3' }
//          ]  
//         );


      const [unAutreTest, SetUnAutreTest]= useState('ALLLO');

        const entites = [
                {
                  entiteMere: "Franssur",
                  entiteShortCode: "AF",
                  sites: [
                    {
                      webSiteName: "Franssur site 1",
                      shortCode: "BDS",
                      formulars : [
                        {
                          name : "formulaire 1",
                          keyform : "F1"
                        },
                        {
                          name : "formulaire 2",
                          keyform : "F2"
                        }
                        
                      ]
                    },
                    {
                      webSiteName: "Franssur site 2",
                      shortCode: "BDG",
                      formulars : [
                        {
                          name : "formulaire 1",
                          keyform : "F3"
                        }
                      ]
                    }
                  ]
                },
                {
                  entiteMere: "Amophys",
                  entiteShortCode: "AX",
                  sites: [
                    {
                      webSiteName: "Amophys site 1",
                      shortCode: "BR",
                      formulars : [
                        {
                          name : "formulaire 1",
                          keyform : "F4"
                        }
                      ]
                    },
                    {
                      webSiteName: "Amophys site 2",
                      shortCode: "EC",
                      formulars : [
                        {
                          name : "formulaire 1",
                          keyform : "F5"
                        }
                      ]
                    }
                  ]
                },
                {
                  entiteMere: "Arca",
                  entiteShortCode: "AC",
                  sites: [
                    {
                      webSiteName: "Arca site 1",
                      shortCode: "BR",
                      formulars : [
                        {
                          name : "formulaire 1",
                          keyform : "F6"
                        }
                      ]
                    },
                    {
                      webSiteName: "Arca site 2",
                      shortCode: "EC",
                      formulars : [
                        {
                          name : "formulaire 1",
                          keyform : "F7"
                        }
                      ]
                    }
                  ]
                }
              ];
            
            
        const [entite, setEntite] = useState("");
        const [site, setSite] = useState("");
        const [formular, setFormular] = useState("");

        // fonction pour mettre a jour le filtre avec la valeur a l'indice correspondant
        const updateFilter = (indice, valeur) => {
        let newTab = [...filterTab];
        newTab[indice] = valeur;
        setFilterTab(newTab); 
        }

        // fonction pour mettre a jour le filtre avec la valeur a l'indice correspondant
        const updateFilterJson = (property, value) => {
                let newTab = {...fitlerTabJson};
                newTab[property] = value;
                setFilterTabJson(newTab);
        }
        
        // edit la valeur de ENTITE et met a jour le filtre
        const handleChangeEntite = (event) => {
                setEntite(event.target.value);
                updateFilter(0,event.target.value);
                setSite("");
                setFormular("");
                
                updateFilterJson ('entity', event.target.value);
        };


        // edit la valeur de SITE et met a jour le filtre
        const handleChangeSite = (event) => {
                setSite(event.target.value);
                setFormular("");
                updateFilterJson('website', event.target.value);
        };
        
        // edit la valeur de FORMULAR et met a jour le filtre
        const handleChangeFormular = (event) => {
                setFormular(event.target.value);
                updateFilterJson('formular', event.target.value);
        };

        // edit la valeur de INPUTVALUEREALISEDBY et met a jour le filtre
        const handleInputChange = (event, newInputValue) => {
                setInputValueRealisedBy(newInputValue);
                updateFilter(inputValueRealisedBy,newInputValue);
                updateFilterJson('inputValueRealisedBy', newInputValue);

        }


        // Periode
        const [period, setPeriod] = useState('');

        // edit la valeur de PERIOD et met a jour le filtre
        const handleChangePeriod = (event) => {
                setPeriod(event.target.value);
                updateFilterJson('period', event.target.value);
                updateFilterJson('lastChange', 'period');
        };


        // Appareil (DEVICE) par défaut "Tous"
        const [device, setDevice] = useState(deviceData[4]);


        // edit la valeur de DEVICE et met a jour le filtre
        const handleChangeDevice = (event) => {
                setDevice(event.target.value);
                updateFilterJson('device', event.target.value);
        };


        // Checkbox
        const [checkedCompared, setCheckedCompared] = useState(false);

        // edit la valeur de checkedCompared et met a jour le filtre
        const handleChangeCheckBox = (event) => {
                setCheckedCompared(event.target.checked);
                console.log(checkedCompared);
                updateFilterJson('checkboxCompared', !checkedCompared);
        };





        // Datepicker Mois/Année
        // const [valueMYMonth,setvalueMYMonth] = useState(new Date());
        // const [valueMYYear, setvalueMYYear ] = useState(new Date());

        // edit la valeur de DEVICE et met a jour le filtre
        // const handleChangeMonthYear = (event) => {
        //         //setvalueMYMonth(event.target.checked);
        //         updateFilterJson('filterJson.intervalleMonthYear.month', valueMYMonth);     
        //         console.log("heure month année : "+valueMYMonth);     
        // };

        // Datepicker Intervalle Mois/Année
        const [valueBeginInterval, setValueBeginInterval] = useState(new Date());
        const [valueEndInterval, setValueEndInterval] = useState(new Date());


        const [selectedDateMY, setSelectedDateMY] = useState(
                new Date("2014-08-18T21:11:54")
        );
            
        const handleDateChangeMY = (date) => {
                setSelectedDateMY(date);
                updateFilterJson('intervalMonth',date)
                updateFilterJson('lastChange', 'intervalMonth');

        };


        // Datepicker Intervalle DEBUT --- FIN
        // Datepicker Intervalle DEBUT

        const [selectedDateBegin, setSelectedDateBegin] = useState(
                new Date("2014-08-18T21:11:54")
        );
            
        const handleDateChangeBegin = (date) => {
                setSelectedDateMY(date);
                updateFilterJson('intervalCalendarBegin',date)
                updateFilterJson('lastChange', 'intervalCalendarBegin');

        };

        // Datepicker Intervalle FIN
        const [selectedDateEnd, setSelectedDateEnd] = useState(
                new Date("2014-08-18T21:11:54")
        );
            
        const handleDateChangeEnd = (date) => {
                setSelectedDateEnd(date);
                updateFilterJson('intervalCalendarEnd',date)
                updateFilterJson('lastChange', 'intervalCalendarEnd');

        };


        // CheckBox pour comparer avec la période précédente
        const [checkedN, setCheckedN] = useState(false);

        // edit la valeur de DEVICE et met a jour le filtre
        const handleChangeN = (event) => {
          setCheckedN(event.target.checked);
          updateFilterJson('checkboxCompared', event.target.value);          
        };

        // const handlemonthwtf = (valueMYMonth) => {
        //         updateFilterJson('filterJson.intervalMonth', valueMYMonth);     

        //         console.log("WTFF "+ valueMYMonth );
        // }

        // const handleyearhwtf = (valueMYYear) => {
        //         updateFilterJson('filterJson.intervalYear', valueMYYear);     

        //         console.log("WTFF 2 "+ valueMYYear );
        // }
        

        const realisedBy = [
                {
                     name: 'Prospect',
                     type:  'General' 
                },
                {
                        name: 'Tous',
                        type: 'General'   
                },
                {
                        name: 'Jean',
                        type: 'Utilisateur ASR'   
                },
                {
                        name: 'Jacques',
                        type: 'Utilisateur ASR'   
                },
                {
                        name: 'Pascal',
                        type: 'Utilisateur ASR'   
                }
        ];

        const options = realisedBy.map(option => option);

        // représente la valeur séléctionnée par l'utilisateur, par exemple quand il presse sur Enter 
        const [valueRealisedBy, setValueRealisedBy ] = useState(realisedBy[1]);
        // représente la valeur affichée dans la textbox.
        const [inputValueRealisedBy, setInputValueRealisedBy] = useState(realisedBy[1].name);
      
      
        //const [filterTab, setFilterTab]= useState(['entite', 'site', 'formulaire', 'realise par', 'periode', 'du', 'au','mois', 'année', 'n-1']);
        const [filterTab, setFilterTab]= useState(['entite', 'site', 'formulaire', inputValueRealisedBy, 'periode', 'du', 'au','mois', 'année', 'n-1']);
 
        const [fitlerTabJson, setFilterTabJson] = useState({
                entity: 'entite', 
                website: 'site', 
                formular: 'formulaire', 
                inputValueRealisedBy: 'Tous', 
                device: 'Tous',
                period: '',  
                intervalMonth : 'month',
                intervalYear : 'year',
                intervalCalendarBegin : 'debut',
                intervalCalendarEnd : 'fin',
                checkboxCompared : false,
                lastChange : 'intervalMonth'         
        });


        // Change la valeur de setDisplay qui permet d'afficher l'onglet correspondant dans le composant "WrapperDisplay" 
        function handleClick(e){
                setDisplay(e.target.value);
        }


        function parseUser(user){
                return (user.split('=')[1]);
        }






        return (

        <StyledWrapperPage color={'#eeeeee'}>
                <StyledWrapperButton>
                        <Button value="tauxReussite" size="small" variant="contained" onClick={handleClick}>Taux de réussite</Button>
                        <Button value="tauxEchec" size="medium" variant="contained" onClick={handleClick}>Taux d'échec</Button>
                        <Button value="venteAdditionnelles" size="large" variant="contained" onClick={handleClick}>Vente additionnelles</Button>
                        <Button value="poidsJours" size="medium" variant="contained" onClick={handleClick}>Poids des jours</Button>
                        <Button value="repartitiongeographique" size="medium" variant="contained" onClick={handleClick}>Répartition géographique</Button>
                        <Button value="repartitionProduit" size="medium" variant="contained" onClick={handleClick}>Répartition par produit</Button>
                </StyledWrapperButton>

                {/* /////////////////////////////////////////////////// */}

                {/* <div>checkedCompared : {checkedCompared.toString()}</div>
                <div>Utilisateur : {user} </div>        
                <div>Utilisateur from search Param : {userSearchParam} </div> 
                <div>Utilisateur from search name : {name} </div> 
                <div>Utilisateur from search tab : {tab} </div>  */}
                {/* <div>userMyUser : {userMyUser.split('=')[1]}</div> */}
                <div>userMyUser : {userMyUser}</div>


                {/* <div>Mon truc = {truc} </div>

                <div>bidule = {bidule}</div> */}

                {/* /////////////////////////////////////////////////// */}


                <div>USer from Storage : {JSON.parse(localStorage.getItem('userFromLocation'))} </div>
                {/* <div>Utilisateur : {user} </div>        






                <div>Utilisateur de STATS par URL DIRECT : {test}</div> */}
                
                        <div><br></br></div>
                        <div>{`Selected DATE : ${selectedDateMY !== null ? `'${selectedDateMY}'` : 'null'}`}</div>

                        

                        {/* <div>{`Date Mois : ${valueMYMonth !== null ? `'${valueMYMonth}'` : 'null'}`}</div>
                        <div>{`Date Année: ${valueMYYear !== null ? `'${valueMYYear}'` : 'null'}`}</div> */}

                        <div>{`Réalisé par : ${inputValueRealisedBy !== null ? `'${inputValueRealisedBy}'` : 'null'}`}</div>
                        <div>{`Intervalle begin: '${valueBeginInterval}'`}</div>
                        <br />
                        <div>{`Intervalle End: '${valueEndInterval}'`}</div>
                        <br />

                <StyledFieldSet>
                        <legend>Critères de recherches :</legend>
                        
                        
                        <StyledFlexDiv>
                        {/* Entités */}
                        <TextField size="small" label="Entité" sx={{ width: 200 }} select value={entite} onChange={handleChangeEntite}>
                        {entites.map((entite) => (
                                <MenuItem
                                value={entite.entiteMere}
                                key={entite.entiteShortCode}
                                >
                                {entite.entiteMere}
                                </MenuItem>
                                ))}
                        </TextField>

                        {/* Site */}
                        <TextField size="small" label="site" sx={{ width: 200 }} select value={site} onChange={handleChangeSite}>
                        {entite
                                ? entites
                                        .find(({ entiteMere }) => entiteMere === entite)
                                        .sites.map((site) => (
                                        <MenuItem value={site.webSiteName} key={site.shortCode}>
                                        {site.webSiteName}
                                        </MenuItem>
                                        ))
                                : []}
                        </TextField>
                        {/* Formulaire */}
                        <TextField size="small" label="Formulaire" sx={{ width: 200 }} select value={formular} onChange={handleChangeFormular}>
                        {site
                                ? entites
                                        .find(({ entiteMere }) => entiteMere === entite).sites
                                        .find(({ webSiteName }) => webSiteName === site)
                                        .formulars.map((formular) => (
                                        <MenuItem value={formular.name} key={formular.keyform}>
                                        {formular.name}
                                        </MenuItem>
                                        ))
                                : []}
                        </TextField>

                        {/* Réaliser par */}
                    
                        <Autocomplete
                                value={valueRealisedBy}
                                onChange={(event: any, newValue: string | null) => {
                                        setValueRealisedBy(newValue);
                                }}
                                inputValue={inputValueRealisedBy}
                                onInputChange={handleInputChange}
                                defaultValue= {options[1].name}
                                id="grouped-demo"
                                options={options}
                                groupBy={(option) => option.type}
                                getOptionLabel={(option) => option.name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Catégorie d'utilisateur"  />}
                        />



                       {/* Periode */}
                       <TextField size="small" label="Periode" sx={{ width: 200 }} select value={period} onChange={handleChangePeriod}>
                                {periodicityData.map((periodicity) => (
                                <MenuItem key={periodicity} value={periodicity}>
                                        {periodicity}
                                </MenuItem>
                        ))}
                        </TextField> 

                        {/* Appareil */}
                        <TextField size="small" label="Appareil" sx={{ width: 200 }} select value={device} onChange={handleChangeDevice}>
                                {deviceData.map((device) => (
                                <MenuItem key={device} value={device}>
                                        {device}
                                </MenuItem>
                        ))}
                        </TextField> 

                        {/* Choix année/mois */}
                        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
     
                                <DatePicker
                                views={['month', 'year']}
                                label="Année et Mois"
                                minDate={new Date('2012-03-01')}
                                maxDate={new Date('2023-06-01')}
                                value={selectedDateMY}                               
                                onChange={handleDateChangeMY} 
                                // onChange={() => {
                                //         setvalueMYMonth(valueMYMonth);
                                //         setvalueMYYear(valueMYMonth);
                                        
                                //         }} 
                                // inputFormat="dd-MM-yyyy"
                                
                                // onMonthChange= {handlemonthwtf}
                                // onYearChange= {handleyearhwtf}
                                // onYearChange= {console.log("ANNEE" + valueMYMonth)}

                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                        
                        </LocalizationProvider>
                       


                            
                        {/* Intervalle */}
                        <fieldset>
                                <legend>Intervalle</legend>
                               
                        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>        
                                <DatePicker
                                views={['month', 'year']}
                                label="Du"
                                minDate={new Date('2012-03-01')}
                                maxDate={new Date('2023-06-01')}
                                value={selectedDateBegin}
                                onChange={handleDateChangeBegin}                            
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                        </LocalizationProvider>
                                
                        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>        
                                <DatePicker
                                views={['month', 'year']}
                                label="Au"
                                minDate={new Date('2012-03-01')}
                                maxDate={new Date('2023-06-01')}
                                value={selectedDateEnd}
                                onChange={handleDateChangeEnd}                            
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                        </LocalizationProvider>      

                        </fieldset>   
                        <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Comparer N-1" onChange={handleChangeCheckBox}/>
                        </FormGroup>
                                
                        </StyledFlexDiv>



                </StyledFieldSet>


                
                <StyledWrapperDisplay>
                        <WrapperDisplay filter={filterTab} filterJson={fitlerTabJson} checked={checkedCompared.toString()} sectionDisplay={display}   test={test}    > </WrapperDisplay>
                </StyledWrapperDisplay>





        </StyledWrapperPage> 



        );
}
 
export default Stats;









