// const TauxEchec = () => {
//         return ( 
//                 <div>Taux d'echec je met carrément tout mon composant</div>
//          );
// }
 
// export default TauxEchec;
import Chart from 'react-apexcharts';
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useState } from "react";

const TauxEchec = () => {
        

        const seriesArray = [
                {
                  name: "Series 1",
                  data: [80, 50, 30, 40, 100, 20]
                },
                {
                  name: "Series 2",
                  data: [20, 30, 40, 80, 20, 80]
                },
                {
                  name: "Series 3",
                  data: [44, 76, 78, 13, 43, 10]
                }
              ];
        
        const [series, setSeries] = useState(seriesArray);
        const [options, setOptions] = useState({                
                chart: {
                        height: 350,
                        type: "radar",
                        dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                        }
                },
                title: {
                        text: "Radar Chart - Multi Series"
                },
                stroke: {
                        width: 2
                },
                fill: {
                        opacity: 0.1
                },
                markers: {
                        size: 0
                },
                xaxis: {
                        categories: ["2011", "2012", "2013", "2014", "2015", "2016"]
                }
                });
        


        const [optionstest, setOptionstest] = useState({});    
        const [seriestest, setSeriestest] = useState([44, 55, 41, 17, 15]);
        const [labelstest, setlabels] = useState(['A', 'B', 'C', 'D', 'E']);




        const [optionMixedChart, setOptionMixedChart] = useState(
                {options: {
                chart: {
                  id: "basic-bar"
                },
                xaxis: {
                  categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
              }});
        const [seriesMixedChart, setSeriesMixedChart] = useState(
                {
                series: [
                        {
                          name: "series-1",
                          data: [30, 40, 45, 50, 49, 60, 70, 91]
                        }
                      ]  
        });



        //mixed chart
        const [seriesMixedCharts, setSeriesMixedCharts] = useState({series: [
                {
                name: "series-1",
                data: [30, 40, 45, 50, 49, 60, 70, 91]
                }
        ]
        });


        const [optionMixedCharts, setOptionMixedCharts] = useState({
                options: {
                chart: {
                id: "basic-bar"
                },
                xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
                }});


        // line Charts
        const [seriesLineCharts, setSeriesLineCharts] = useState({series: [{
                name: "Desktops",
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
            }]});

        const [optionLineCharts, setOptionLineCharts] = useState({options: {
                chart: {
                  height: 350,
                  type: 'line',
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'straight'
                },
                title: {
                  text: 'Product Trends by Month',
                  align: 'left'
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                  },
                },
                xaxis: {
                  categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                }
              }});
        
        

        // bar
        const [optionBarCharts ,  setOptionBarCharts] = useState({
                chart: {
                                id: 'apexchart-example'
                        },
                        xaxis: {
                                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
                        }
              });

              
        const [seriesBarCharts , setSeriesBarCharts] = useState([{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
                }]);





        const [optionAreaCharts ,  setoptionAreaCharts] = useState(
                {
                chart: {
                height: 350,
                type: 'area'
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
              });
          
        const [seriesAreaCharts ,  setseriesAreaCharts] = useState(  [{
                name: 'series1',
                data: [31, 40, 28, 51, 42, 109, 100]
              }, {
                name: 'series2',
                data: [11, 32, 45, 32, 34, 52, 41]
              }]);

          
          
           


        return (

<>


<Box style={{
                backgroundColor:"rgb(234, 237, 242)"
        }} sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
        <Chart options={optionstest} series={seriestest} type="donut" width="450" />
        </Grid>
        <Grid div xs={4}>
        <Chart options={optionstest} series={seriestest} type="donut" width="450" />
        </Grid>
        <Grid div xs={4}>
        <Chart options={optionstest} series={seriestest} type="donut" width="450" />
        </Grid>
        <Grid div xs={8}>
        <Chart options={optionstest} series={seriestest} type="donut" width="450" />
        </Grid>
      </Grid>
    </Box>



<Box style={{
                backgroundColor:"rgb(153,153,153) "
        }} sx={{ flexGrow: 1 }}>

        <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        style={{ minHeight: '100px' }}

        >

                <Grid item xs={3}>
                        <Chart options={optionstest} series={seriestest} type="donut" width="450" />
                </Grid>   
                <Grid item xs={3}>
                        <Chart options={optionstest} series={seriestest} type="donut" width="450" />
                </Grid>   
        
        </Grid> 
</Box>

             
<Box style={{
                backgroundColor:"rgb(150,222,209)"
        }} sx={{ flexGrow: 1 }}>

        <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        style={{ minHeight: '100px' }}

        >

                <Grid item xs={3}>
                <div id="chart">
                                <Chart
                                options={options}
                                series={series}
                                type="radar"
                                height={350}
                                />
                        </div>
                </Grid>   
                <Grid item xs={3}>
                <div id="chart">
                                <Chart
                                options={options}
                                series={series}
                                type="radar"
                                height={350}
                                />
                        </div>
                </Grid>   
        
        </Grid> 
</Box>

<Box style={{
                backgroundColor:"rgb(147,197,114)"
        }} sx={{ flexGrow: 1 }}>
<Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        style={{ minHeight: '100px' }}

        >

                <Grid item xs={3}>
                <div id="chart">
                        <Chart options={optionBarCharts} series={seriesBarCharts} type="bar" width={500} height={320} />   

                        </div>
                </Grid>   
                <Grid item xs={3}>
                <div id="chart">
                        <Chart options={optionBarCharts} series={seriesBarCharts} type="line" width={700} height={700} />   

                        </div>
                </Grid>   
        
        </Grid> 
</Box>

<Box style={{
                backgroundColor:"rgb(255,255,128) "
        }} sx={{ flexGrow: 1 }}>
<Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        style={{ minHeight: '100px' }}

        >

                <Grid item xs={3}>
                <div id="chart">
                        <Chart options={optionAreaCharts} series={seriesAreaCharts} type="area" width={500} height={320} />   

                        </div>
                </Grid>   
                <Grid item xs={3}>
                <div id="chart">
                <Chart options={optionAreaCharts} series={seriesAreaCharts} type="area" width={500} height={320} />   

                </div>
                </Grid>   
        
        </Grid> 
</Box>




     

       
    



</>
               
              
        );
      
}
 
export default TauxEchec;