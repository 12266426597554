const TauxReussite = ({checked, test}) => {

        return (
                <>
                     <div>Taux de reussite c'est bien mon composant</div>
                        {/* <div>checkedCompared : { checked!== undefined ? {checked} : 'undefined'}</div>  */}
                        <div>checkedCompared : { checked}</div> 
                        <h1>mon test : {test}</h1>
                </>
                
        );
}
 
export default TauxReussite;